var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "paymentInfo-wrap"
  }, [_c('h2', [_vm._v("Payment Information")]), _c('p', [_vm._v("Select payment method or add new one.")]), _c('div', {
    staticClass: "paymentInfo-form",
    attrs: {
      "id": "selector"
    }
  }, [_c('div', {
    staticClass: "custom-control custom-switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isAch,
      expression: "isAch"
    }],
    staticClass: "custom-control-input bank-details-switch",
    attrs: {
      "type": "checkbox",
      "id": "customSwitch1"
    },
    domProps: {
      "checked": Array.isArray(_vm.isAch) ? _vm._i(_vm.isAch, null) > -1 : _vm.isAch
    },
    on: {
      "click": _vm.changePaymentMethod,
      "change": function change($event) {
        var $$a = _vm.isAch,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.isAch = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isAch = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isAch = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-switch-span",
    attrs: {
      "for": "customSwitch1"
    }
  }, [_vm._v(" Supply bank information at a later date ")]), _c('label', {
    staticClass: "custom-control-label ach-label",
    attrs: {
      "for": "customSwitch1"
    }
  }, [_vm._v(" ACH ")])]), _vm.paymentMethod === 'check' ? _c('div', {
    staticClass: "check form-cheque"
  }, [_c('h3', [_vm._v("Supply bank information at a later date")]), _c('div', {
    staticClass: "form-group row ai-location-wrapper"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Address Information "), _c('i', {
    staticClass: "fas fa-plus-circle",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$bvModal.show('addPaymentAddress');
      }
    }
  })]), _vm._l(_vm.addressInfo, function (address) {
    return _c('div', {
      key: address.id,
      staticClass: "col-md-6 col-sm-12 mb-3"
    }, [address.is_active ? _c('div', {
      staticClass: "location-div primary-location"
    }, [_c('p', [_vm._v(" " + _vm._s(address.address1) + ", " + _vm._s(address.address2) + " "), _c('i', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      class: address.usps_verified ? 'fas fa-check-circle' : 'fas fa-exclamation-triangle',
      attrs: {
        "title": address.usps_verified ? 'Validated with USPS' : 'Not validated with USPS'
      }
    }), _c('br'), _vm._v(" " + _vm._s(address.city) + ", " + _vm._s(address.state) + ", " + _vm._s(address.zip) + " ")]), _c('button', {
      staticClass: "primaryAdrs-btn"
    }, [_vm._v("Primary")])]) : _c('div', {
      staticClass: "location-div set-as-primary"
    }, [_c('p', [_vm._v(" " + _vm._s(address.address1) + ", " + _vm._s(address.address2) + " "), _c('i', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      class: address.usps_verified ? 'fas fa-check-circle' : 'fas fa-exclamation-triangle',
      attrs: {
        "title": address.usps_verified ? 'Validated with USPS' : 'Not validated with USPS'
      }
    }), _c('br'), _vm._v(" " + _vm._s(address.city) + ", " + _vm._s(address.state) + ", " + _vm._s(address.zip) + " ")]), _c('button', {
      staticClass: "primaryAdrs-btn",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.updateAsPrimary(address.id);
        }
      }
    }, [_vm._v(" Set as Primary ")]), _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "ai-delete-a",
      attrs: {
        "type": "button",
        "title": "Delete"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteAddress(address.id);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-trash"
    })])])]);
  })], 2)]) : _vm._e(), _vm.paymentMethod === 'ach' ? _c('div', {
    staticClass: "ach form-ach"
  }, [_c('h3', [_vm._v(" ACH "), _c('i', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.addAch",
      modifiers: {
        "addAch": true
      }
    }],
    staticClass: "fas fa-plus-circle"
  })]), _c('div', {
    staticClass: "fileListing-card"
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.achList, function (ach) {
    return _c('tr', {
      key: ach.id
    }, [_c('td', [_c('span', {
      staticClass: "date"
    }, [_vm._v(_vm._s(ach.payee_name))])]), _c('td', [_c('span', {
      staticClass: "username"
    }, [_vm._v(_vm._s(ach.bank))])]), _c('td', [_c('span', {
      staticClass: "username"
    }, [_vm._v(_vm._s(ach.account_number))])]), _c('td', [_c('span', {
      staticClass: "username-code"
    }, [_vm._v(" " + _vm._s(ach.routing_number) + " ")])]), _c('td', [_c('div', {
      staticClass: "action-icons"
    }, [ach.is_active ? _c('button', {
      staticClass: "primaryAdrs-btn"
    }, [_vm._v(" Primary ")]) : _c('button', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "primaryAdrs-btn set-as-primary",
      attrs: {
        "title": "Set as primary"
      },
      on: {
        "click": function click($event) {
          return _vm.setAsPrimary(ach.id);
        }
      }
    }, [_vm._v(" Set as Primary ")]), !ach.is_active ? _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "ai-delete-a",
      attrs: {
        "type": "button",
        "title": "Delete"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteAch(ach.id);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-trash"
    })]) : _vm._e()])])]);
  }), 0)])])])]) : _vm._e(), _c('div', {
    staticClass: "addAch-popup"
  }, [_c('b-modal', {
    attrs: {
      "id": "addAch",
      "centered": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "addAch-header"
        }, [_c('h4', [_vm._v("Add New ACH")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeAchModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 text-center"
        }, [_c('b-button', {
          staticClass: "univ-btn",
          on: {
            "click": _vm.handleAchSubmit
          }
        }, [_vm._v(" Add ACH ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "addAch-form"
  }, [_c('div', {
    ref: "achAddContainer",
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-xxl-4 col-lg-4 col-md-6 mb-3"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Payee Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paymentInfo.payee_name,
      expression: "paymentInfo.payee_name"
    }],
    staticClass: "accountInfo-field",
    attrs: {
      "type": "text",
      "placeholder": "Enter Payee Name",
      "name": "payee_name"
    },
    domProps: {
      "value": _vm.paymentInfo.payee_name
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.paymentInfo, "payee_name", $event.target.value);
      }, _vm.handleInputChange]
    }
  }), _vm.errors.payee_name ? _c('div', {
    staticClass: "error-message"
  }, [_c('small', [_vm._v(_vm._s(_vm.errors.payee_name))])]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-4 col-lg-4 col-md-6 mb-3"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Routing Number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paymentInfo.routing_number,
      expression: "paymentInfo.routing_number"
    }],
    staticClass: "accountInfo-field",
    attrs: {
      "type": "text",
      "placeholder": "Enter Routing Number",
      "name": "routing_number"
    },
    domProps: {
      "value": _vm.paymentInfo.routing_number
    },
    on: {
      "change": _vm.updateRoutingNumber,
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.paymentInfo, "routing_number", $event.target.value);
      }
    }
  }), _vm.errors.routing_number ? _c('div', {
    staticClass: "error-message"
  }, [_c('small', [_vm._v(_vm._s(_vm.errors.routing_number))])]) : _vm._e()]), _c('input-text', {
    attrs: {
      "rules": {
        required: true
        /*regex:/^([a-zA-Z0-9 ])*$/ */

      },
      "labelFor": "bank",
      "labelName": "Bank Name",
      "inputName": "bank_name",
      "inputClass": "bank_name accountInfo-field",
      "vmodel": _vm.paymentInfo.bank,
      "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3",
      "disabled": true
    }
  })], 1), _c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-xxl-4 col-lg-4 col-md-6 mb-3"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Account Number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paymentInfo.account_number,
      expression: "paymentInfo.account_number"
    }],
    staticClass: "accountInfo-field",
    attrs: {
      "type": "text",
      "placeholder": "Enter Account Number",
      "name": "account_number"
    },
    domProps: {
      "value": _vm.paymentInfo.account_number
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.paymentInfo, "account_number", $event.target.value);
      }, _vm.handleInputChange]
    }
  }), _vm.errors.account_number ? _c('div', {
    staticClass: "error-message"
  }, [_c('small', [_vm._v(_vm._s(_vm.errors.account_number))])]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-4 col-lg-4 col-md-6 mb-3"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Account Type")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paymentInfo.account_type,
      expression: "paymentInfo.account_type"
    }],
    staticClass: "accountInfo-field",
    attrs: {
      "name": "account_type"
    },
    on: {
      "input": _vm.handleInputChange,
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.paymentInfo, "account_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "selected": "",
      "value": ""
    }
  }, [_vm._v("Select Your Account Type")]), _c('option', {
    attrs: {
      "value": "Savings"
    }
  }, [_vm._v("Savings")]), _c('option', {
    attrs: {
      "value": "Checking"
    }
  }, [_vm._v("Checking")])]), _vm.errors.account_type ? _c('div', {
    staticClass: "error-message"
  }, [_c('small', [_vm._v(_vm._s(_vm.errors.account_type))])]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-4 col-lg-4 col-md-6 mb-3"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Account Holder Type")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paymentInfo.account_holder_type,
      expression: "paymentInfo.account_holder_type"
    }],
    staticClass: "accountInfo-field",
    attrs: {
      "name": "account_holder_type"
    },
    on: {
      "input": _vm.handleInputChange,
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.paymentInfo, "account_holder_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "selected": "",
      "value": ""
    }
  }, [_vm._v(" Select Account Holder Type ")]), _c('option', {
    attrs: {
      "value": "Individual"
    }
  }, [_vm._v("Individual")]), _c('option', {
    attrs: {
      "value": "Company"
    }
  }, [_vm._v("Company")])]), _vm.errors.account_holder_type ? _c('div', {
    staticClass: "error-message"
  }, [_c('small', [_vm._v(_vm._s(_vm.errors.account_holder_type))])]) : _vm._e()])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "signature"
  }, [_c('label', [_vm._v(" Signature "), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")])]), _c('VueSignaturePad', {
    ref: "signaturePad",
    staticStyle: {
      "border": "1px solid rgb(206, 212, 218)"
    },
    attrs: {
      "width": "100%",
      "height": "200px",
      "options": {
        onBegin: _vm.onBegin,
        onEnd: _vm.onEnd
      }
    }
  }), _c('div', {
    staticClass: "text-right",
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('a', {
    staticClass: "clearSignature",
    staticStyle: {
      "background": "#0ab5e0",
      "padding": "5px 25px",
      "display": "inline-block",
      "border-radius": "4px",
      "margin-top": "10px",
      "color": "#fff",
      "text-decoration": "none"
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.undo
    }
  }, [_vm._v(" Undo ")])])], 1)])])])])], 1)], 1)]), _c('new-payment-address', {
    on: {
      "onSuccessPaymentAdd": _vm.updatePaymentMethodViaCheck,
      "onClosePaymentModal": _vm.getPaymentData
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Name")]), _c('th', [_vm._v("Bank")]), _c('th', [_vm._v("Account Number")]), _c('th', [_vm._v("Routing")]), _c('th', [_vm._v("Action")])])]);
}]

export { render, staticRenderFns }