<template>
  <div class="personalInfo-wrap">
    <h2>Personal Information</h2>
    <p>Enter and Update all your Personal Information here.</p>
    <div class="personalInfo-form">
      <form action="">
        <div class="form-group row">
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label for=""
              >Agent Code <span class="required-indc">*</span></label
            >
            <input
              type="text"
              placeholder="Enter Agent Code"
              name="agent_code"
              class="accountInfo-field"
              v-model="personalInfo.agent_code"
              disabled
            />
            <div v-if="errors.agent_code" class="error-message">
              <small>{{ errors.agent_code }}</small>
            </div>
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label for="">DOB <span class="required-indc">*</span></label>
            <date-picker
              input-class="accountInfo-field"
              placeholder="MM/DD/YYYY"
              value-type="format"
              format="MM/DD/YYYY"
              v-model="personalInfo.dob"
            ></date-picker>
            <div v-if="errors.dob && !personalInfo.dob" class="error-message">
              <small>{{ errors.dob }}</small>
            </div>
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label for="">SSN <span class="required-indc">*</span></label>
            <input
              type="text"
              placeholder="Enter SSN Number"
              name="ssn"
              class="accountInfo-field"
              v-model="personalInfo.ssn"
              @input="handleInputChange"
            />
            <div v-if="errors.ssn" class="error-message">
              <small>{{ errors.ssn }}</small>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label for=""
              >First Name <span class="required-indc">*</span></label
            >
            <input
              type="text"
              placeholder="Enter First Name"
              name="first_name"
              class="accountInfo-field"
              v-model="personalInfo.first_name"
              @input="handleInputChange"
            />
            <div v-if="errors.first_name" class="error-message">
              <small>{{ errors.first_name }}</small>
            </div>
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label for="">Middle Name </label>
            <input
              type="text"
              placeholder="Enter Middle Name"
              name="middle_name"
              class="accountInfo-field"
              v-model="personalInfo.middle_name"
              @input="handleInputChange"
            />
            <div v-if="errors.middle_name" class="error-message">
              <small>{{ errors.middle_name }}</small>
            </div>
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label for="">Last Name <span class="required-indc">*</span></label>
            <input
              type="text"
              placeholder="Enter Last Name"
              name="last_name"
              class="accountInfo-field"
              v-model="personalInfo.last_name"
              @input="handleInputChange"
            />
            <div v-if="errors.last_name" class="error-message">
              <small>{{ errors.last_name }}</small>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label for="">Telephone </label>
            <input
              type="text"
              placeholder="eg: 1235688978 / no dashes"
              name="telephone"
              class="accountInfo-field"
              v-model="personalInfo.telephone"
              @input="handleInputChange"
            />
            <div v-if="errors.telephone" class="error-message">
              <small>{{ errors.telephone }}</small>
            </div>
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label for="">Fax</label>
            <input
              type="text"
              placeholder="eg: 1235688978 / no dashes"
              name="fax"
              class="accountInfo-field"
              v-model="personalInfo.fax"
            />
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label for=""
              >Cell Phone <span class="required-indc">*</span></label
            >
            <input
              type="text"
              placeholder="eg: 1235688978 / no dashes"
              name="cell_phone"
              class="accountInfo-field"
              v-model="personalInfo.cell_phone"
              @input="handleInputChange"
            />
            <div v-if="errors.cell_phone" class="error-message">
              <small>{{ errors.cell_phone }}</small>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label for="">800 Number </label>
            <input
              type="text"
              placeholder="eg: 800688978 / no dashes"
              name="num_800"
              class="accountInfo-field"
              v-model="personalInfo.num_800"
            />
          </div>
          <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
            <label for=""
              >Email Address <span class="required-indc">*</span></label
            >
            <input
              type="email"
              placeholder="Enter Email Address"
              name="email"
              class="accountInfo-field"
              v-model="personalInfo.email"
              @input="handleInputChange"
            />
            <div v-if="errors.email" class="error-message">
              <small>{{ errors.email }}</small>
            </div>
          </div>
        </div>

        <div class="form-group row image-row">
        
          <input-image
              @deleteImage="deleteImage('personal')"
              :rules="{ required: false }"
              ref="image"
              labelFor="image"
              labelName="Upload Image"
              :vmodel.sync="agentImg"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4 image-row"
              :imgSrc="imgSrc"
              imgClass="downline-upload-img"
            />
          
        </div>
        <div class="form-group row ai-location-wrapper">
          <label for=""
            >Address Information
            <i
              class="fas fa-plus-circle"
              v-b-modal.addAddress
            ></i
          ></label>
          <div
            v-for="address in addressInfo"
            :key="address.id"
            class="col-md-6 col-sm-12 mb-3"
          >
            <div v-if="address.is_active" class="location-div primary-location">
              <p>
                {{ address.address1 }}, {{ address.address2 }}
                <i
                  :class="
                    address.usps_verified
                      ? 'fas fa-check-circle'
                      : 'fas fa-exclamation-triangle'
                  "
                  v-b-tooltip.hover
                  :title="
                    address.usps_verified
                      ? 'Validated with USPS'
                      : 'Not validated with USPS'
                  "
                ></i>
                <br />{{ address.city }}, {{ address.state }}, {{ address.zip }}
              </p>
              <button class="primaryAdrs-btn">Primary</button>
            </div>
            <div v-else class="location-div set-as-primary">
              <p>
                {{ address.address1 }}, {{ address.address2 }}
                <i
                  :class="
                    address.usps_verified
                      ? 'fas fa-check-circle'
                      : 'fas fa-exclamation-triangle'
                  "
                  v-b-tooltip.hover
                  :title="
                    address.usps_verified
                      ? 'Validated with USPS'
                      : 'Not validated with USPS'
                  "
                ></i>
                <br />{{ address.city }}, {{ address.state }}, {{ address.zip }}
              </p>
              <button
                type="button"
                class="primaryAdrs-btn"
                @click="updateAsPrimary(address.id)"
              >
                Set as Primary
              </button>
              <a
                class="ai-delete-a"
                type="button"
                v-b-tooltip.hover
                title="Delete"
                @click="deleteAddress(address.id)"
                ><i class="fas fa-trash"></i
              ></a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
            <button
              type="button"
              class="univ-btn"
              v-on:click="updatePersonalInfo()"
            >
              {{ this.processing ? "Processing..." : "Update" }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Api from "../../resource/Api";
import Helper from "../../resource/Helper";
import dummyImage from "@/assets/images/upload-dummy.png";

const emptyPersonalInfo = {
  agent_code: "",
  name: "",
  dob: "",
  ssn: "",
  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  zip: "",
  telephone: "",
  fax: "",
  cell_phone: "",
  num_800: "",
  email: "",
  default_payment_type: "",
};

export default {
  name: "PersonalInfo",
  props: {
      tabIndex:{
          required:true
      }
  },
  data() {
    return {
      type: "personal",
      version: process.env.VUE_APP_API_VERSION,
      personalInfo: { ...emptyPersonalInfo },
      agentImage: {
        image: "",
      },
      addressInfo: [],
      imageUpdate: false,
      errors: [],
      processing: false,
      dateFormat: "DD/MM/YYYY",
      dummyImage,
      imgSrc: "",
      updateInfoInStorage: false,
      agentImg: null
    };
  },
  created() {
    this.getAgentPersonalInfo();
    this.getPersonalAddressList();
  },
  computed: {
    addressStatus() {
      return this.$store.getters.getAddressListStatus;
    },
  },
  watch: {
    addressStatus: {
      handler: function (newValue) {
        if (newValue) {
          this.getPersonalAddressList();
        }
      },
    },
  },
  methods: {
    setPersonalData(data) {
      let [repData, name] = [
        this.$store.getters.rep,
        `${data.first_name} ${data.middle_name} ${data.last_name}`,
      ];
      const newRepData = {
        id: repData.id,
        name: name,
        email: data.email,
        phone: Helper.formatPhoneNumber(data.cell_phone),
        image: data.image,
        code: data.agent_code,
      };
      this.$refs.image.value = null;
      this.updateInfoInStorage = false;
      this.$store.commit("setRepData", newRepData);
    },
    getAgentPersonalInfo() {
      Api.get(`${this.version}/get-agent-info`)
        .then((response) => {
          if (response.data.status == "success") {
            this.personalInfo = { ...response.data.data.personal };
            this.imgSrc = this.personalInfo.image;

            //storing in cookie
            if (this.updateInfoInStorage) {
              this.setPersonalData(this.personalInfo);
            }
          } else {
            Api.notification(response.data.status, response.data.message);
            console.log(response);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getPersonalAddressList() {
      Api.get(`${this.version}/get-address-list?type=${this.type}`)
        .then((response) => {
          if (response.data.status == "success") {
            this.addressInfo = [...response.data.data];
            //status false to end loop function call from watch
            this.$store.commit("setAddressInfoStatus", false);
          } else {
            console.log(response);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async updateAsPrimary(id) {
      const proceed = await Helper.confirmDialog(
        "Confirm",
        "Are you sure you want to update primary address?"
      );
      if (proceed) {
        Api.post(`${this.version}/set-primary-address`, {
          type: this.type,
          address_id: id,
        })
          .then((response) => {
            if (response.data.status == "success") {
              this.getPersonalAddressList();
              Api.notification(response.data.status, response.data.message);
            } else {
              Api.notification(response.data.status, response.data.message);
            }
          })
          .catch((e) => {
            console.log(e.response.data.message);
            Api.notification("error", e.response.data.message);
          });
      }
    },
    async deleteAddress(id) {
      const proceed = await Helper.confirmDialog(
        "Confirm",
        "Are you sure you want to delete this address?"
      );

      if (proceed) {
        Api.delete(`${this.version}/delete-agent-address`, {
          type: this.type,
          address_id: id,
        })
          .then((response) => {
            if (response.data.status == "success") {
              this.getPersonalAddressList();
              Api.notification(response.data.status, response.data.message);
            } else {
              Api.notification(response.data.status, response.data.message);
            }
          })
          .catch((e) => {
            console.log(e.response.data.message);
            Api.notification("error", e.response.data.message);
          });
      }
    },
    //update informations
    updateImage() {
      let data = new FormData();
      data.append("type", this.type);
      data.append("image", this.agentImg);
      this.agentImage.image = data;
      this.imageUpdate = true;
      this.imgSrc = URL.createObjectURL(this.agentImg);
    },
    validatePersonalInfo() {
      let result = true;
      let validationArr = [];
      let re = /^[0-9]*$/;

      if (!this.personalInfo.agent_code) {
        validationArr["agent_code"] = "Agent code cannot be empty";
        result = false;
      }

      if (!this.personalInfo.first_name) {
        validationArr["first_name"] = "First Name cannot be empty";
        result = false;
      }

      if (
        this.personalInfo.middle_name &&
        this.personalInfo.middle_name.length > 1
      ) {
        validationArr["middle_name"] =
          "Middle Name cannot be more than 1 character";
        result = false;
      }

      if (!this.personalInfo.last_name) {
        validationArr["last_name"] = "Last Name cannot be empty";
        result = false;
      }

      if (!this.personalInfo.dob) {
        validationArr["dob"] = "DOB cannot be empty";
        result = false;
      }

      if (!this.personalInfo.ssn) {
        validationArr["ssn"] = "SSN cannot be empty";
        result = false;
      } else if (this.personalInfo.ssn && this.personalInfo.ssn.length != 9) {
        validationArr["ssn"] = "SSN should be of 9 digits";
        result = false;
      }

      if (!this.personalInfo.cell_phone) {
        validationArr["cell_phone"] = "Cell Phone cannot be empty";
        result = false;
      } else if (
        this.personalInfo.cell_phone &&
        this.personalInfo.cell_phone.length != 10
      ) {
        validationArr["cell_phone"] = "Cell phone should be of 10 digits";
        result = false;
      } else if (!re.test(this.personalInfo.cell_phone)) {
        validationArr["cell_phone"] = "Cell phone should be in number";
        result = false;
      }

      if (!this.personalInfo.email) {
        validationArr["email"] = "Email Address cannot be empty";
        result = false;
      }

      if (
        this.personalInfo.telephone &&
        this.personalInfo.telephone.length != 10
      ) {
        validationArr["telephone"] =
          "Telephone should be of 10 digits or can be empty";
        result = false;
      } else if (!re.test(this.personalInfo.telephone)) {
        validationArr["telephone"] = "Telephone should be in number";
        result = false;
      }

      this.errors = validationArr;
      return result;
    },
    handleInputChange(event) {
      this.$set(this.errors, event.target.name, "");
    },
    updatePersonalInfo() {
      this.processing = true;
      this.updateInfoInStorage = true;
      let validation = this.validatePersonalInfo();
      if(this.agentImg){
        this.updateImage()
      }
      let updatePersonalInfoData = Object.assign({}, this.personalInfo);
      updatePersonalInfoData.image = '';
      if (validation) {
        let dob = this.formatDate();
        Api.post(`${this.version}/update-agent-personal`, {
          ...updatePersonalInfoData,
          dob,
        })
          .then((response) => {
            if (response.data.status == "success") {
              if (!this.imageUpdate) {
                this.processing = false;
                this.getAgentPersonalInfo();
              }
              Api.notification(response.data.status, response.data.message);
            } else if (response.data.statusCode == 401) {
              this.processing = false;
              Api.notification(response.data.status, response.data.message);
            }
          })
          .catch((e) => {
            this.processing = false;
            console.log(e.response.data.message);
            Api.notification("error", e.response.data.message);
          });

        if (this.imageUpdate) {
          Api.post(`${this.version}/update-agent-image`, this.agentImage.image)
            .then((response) => {
              if (response.data.status == "success") {
                this.imageUpdate = false;
                this.processing = false;
                Api.notification(response.data.status, response.data.message);
                this.getAgentPersonalInfo();
              } else if (response.data.statusCode == 401) {
                this.processing = false;
                Api.notification(response.data.status, response.data.message);
              }
            })
            .catch((e) => {
              this.processing = false;
              console.log(e.response.data.message);
              Api.notification("error", e.response.data.message);
            });
        }
      } else {
        this.processing = false;
      }
    },
    formatDate() {
      let date = new Date(this.personalInfo.dob);
      let dob =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2);
      return dob;
    },
    async deleteImage(type) {
         await this.$emit('deleteImage',type);
    }
    // setType() {
    //   this.$store.commit("setCurrentAccountInfoType", this.type);
    // },
  },
};
</script>
<style>
.downline-upload-img {
    /* height: 150px; */
    width: 200px;
    margin-top: 5px;
}
.upload-close-icon {
    max-width: 24px !important;
    max-height: 24px !important;
    position: absolute;
    cursor: pointer;
    margin-left: -14px;
}
.uploaded {
    width: 100%;
    position: relative;
}
</style>