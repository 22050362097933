<template>
  <div class="addAddress-popup">
    <b-modal id="addAddress" centered size="lg" v-model="show">
      <template #modal-header>
        <div class="addAddress-header">
          <h4>Add New Address</h4>

          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="addAddress-form" >
          <div class="form-group row" ref="addAddressContainer">
            <div class="col-xxl-6 col-lg-6 col-md-6 mb-3">
              <label for=""
                >Address 1 <span class="required-indc">*</span></label
              >
              <input
                type="text"
                placeholder="Enter Address 1"
                name="address1"
                class="accountInfo-field"
                v-model="formData.address1"
                @input="handleInputChange"
              />
              <div v-if="errors.address1" class="error-message">
                <small>{{ errors.address1 }}</small>
              </div>
            </div>
            <div class="col-xxl-6 col-lg-6 col-md-6 mb-3">
              <label for="">Address 2</label>
              <input
                type="text"
                placeholder="Enter Address 2"
                name="address2"
                class="accountInfo-field"
                v-model="formData.address2"
              />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xxl-6 col-lg-6 col-md-6 mb-3">
              <label for="">City <span class="required-indc">*</span></label>
              <input
                type="text"
                placeholder="Enter City"
                name="city"
                class="accountInfo-field"
                v-model="formData.city"
                @input="handleInputChange"
              />
              <div v-if="errors.city" class="error-message">
                <small>{{ errors.city }}</small>
              </div>
            </div>
            <div class="col-xlx-6 col-lg-6 col-md-6 mb-3">
              <label for="">State <span class="required-indc">*</span></label>
              <select
                class="accountInfo-field"
                name="state"
                v-model="formData.state"
                @input="handleInputChange"
              >
                <option value="" selected>Select Your State</option>
                <option
                  v-for="state in states"
                  :key="state.value"
                  :value="state.value"
                >
                  {{ state.text }}
                </option>
              </select>
              <div v-if="errors.state" class="error-message">
                <small>{{ errors.state }}</small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xxl-6 col-lg-6 col-md-6 mb-3">
              <label for="">Zip <span class="required-indc">*</span></label>
              <input
                type="text"
                placeholder="Enter Zip Code"
                name="zip"
                class="accountInfo-field"
                v-model="formData.zip"
                @input="handleInputChange"
              />
              <div v-if="errors.zip" class="error-message">
                <small>{{ errors.zip }}</small>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="form-check col-xxl-6 col-lg-6 col-md-6 mb-3">
              <toggle-element
                :vmodel.sync="formData.is_primary"
                @onClicked="formData.is_primary = !formData.is_primary"
                text="Set as Primary"
              />
            </div>
            <div class="form-check col-xxl-6 col-lg-6 col-md-6 mb-3">
              <input
                class="form-check-input add-new-address-checkbox"
                type="checkbox"
                id="flexCheckDefault"
                v-model="formData.usps_verified"
                @click="uspsValidation"
              />
              <label class="form-check-label" for="flexCheckDefault">
                Validate with USPS
              </label>
            </div>
          </div>
        </div>
      </b-container>

      <template #modal-footer>
        <div class="w-100 text-center">
          <b-button v-if="processing" class="univ-btn no-cursor" disabled
            >Processing...</b-button
          >
          <b-button v-else class="univ-btn" @click="handleAddressSubmit"
            >Submit</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Api from "@/resource/Api.js";
import Helper from "@/resource/Helper"
const emptyFormData = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  usps_verified: false,
  is_primary: true,
};

export default {
  name: "NewAddress",
  props: {
    type: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      show: false,
      variants: ["close-btn"],
      formData: { ...emptyFormData },
      states: Helper.getStates(),
      errors: [],
      version: process.env.VUE_APP_API_VERSION,
      processing: false,
    };
  },
  mounted() {
    // Api.get(`${this.version}/get-states`).then((response) => {
    //   if (response.data.status == "success") {
    //     this.states = [...response.data.data];
    //   } else {
    //     console.log(response);
    //   }
    // });
  },
  //   computed: {
  //     type() {
  //       return this.$store.getters.getCurrentAccountInfoType;
  //     },
  //   },
  methods: {
    uspsValidation(event) {
      let proceed = event.target.checked;
      let validate = this.validateAddress();

      if (proceed && validate) {
        this.processing = true;
        Api.post(`v1/validate-address-usps`, this.formData)
          .then((response) => {
            if (response.data.status == "success") {
              this.processing = false;
              Api.notification(response.data.status, response.data.message);
            } else {
              this.processing = false;
              this.$set(this.formData, "usps_verified", false);
              Api.notification(response.data.status, response.data.message);
            }
          })
          .catch((e) => {
            this.processing = false;
            this.$set(this.formData, "usps_verified", false);
            Api.notification(
              e.response.data.status,
              e.response.data.data[0].error_message
            );
          })
          .finally(() => {
            this.processing = false;
          });
      } else {
        this.processing = false;
      }
    },
    validateAddress() {
      let result = true;
      let errorArr = [];

      if (!this.formData.address1) {
        errorArr["address1"] = "Address 1 cannot be empty";
        result = false;
      }

      if (!this.formData.city) {
        errorArr["city"] = "City cannot be empty";
        result = false;
      }

      if (!this.formData.state) {
        errorArr["state"] = "Please select your state";
        result = false;
      }

      if (!this.formData.zip) {
        errorArr["zip"] = "Zip code cannot be empty";
        result = false;
      } else if (this.formData.zip.length != 5) {
        errorArr["zip"] = "Zip code should be 5 digits long";
        result = false;
      }

      this.errors = errorArr;
      return result;
    },
    handleInputChange(event) {
      this.$set(this.errors, event.target.name, "");
      this.$set(this.formData, "usps_verified", false);
    },
    handleAddressSubmit() {
      const validation = this.validateAddress();

      if (validation) {
           let loader = this.$loading.show({
          container: this.$refs.addAddressContainer,
        });
        this.processing = true;
        Api.post(`${this.version}/add-address`, {
          ...this.formData,
          type: this.type,
        })
          .then((response) => {
            if (
              response.data.status == "success" &&
              response.data.statusCode == 201
            ) {
              this.show = false;
              this.$store.commit("setAddressInfoStatus", true);
              this.formData = { ...emptyFormData };
              Api.notification(response.data.status, response.data.message);
              this.$emit("onSuccessPaymentAdd");
              this.closeModal();
            } else if (response.data.statusCode == 401) {
              Api.notification(response.data.status, response.data.message);
            } else {
              console.log(response);
            }
          })
          .catch((e) => {
            console.log(e.response.data.message);
            Api.notification("error", "Failed to add address");
          })
          .finally(()=>{
              loader.hide()
              this.processing = false
          })
      }
    },
    closeModal() {
      this.errors = [];
      this.show = false;
    },
  },
};
</script>
<style>
#addAddress .modal-content {
  border: 0px;
  border-radius: 10px;
}
#addAddress .modal-header {
  justify-content: center;
  border: 0;
  padding: 25px 0;
  position: relative;
}
#addAddress .modal-footer {
  border-top: 0;
  padding: 0 15px 25px 15px;
}
.addAddress-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
  margin: 0;
}
.addAddress-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.addAddress-form label {
  color: #5e5e5e;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}
#addAddress .modal-footer .univ-btn {
  max-width: 300px;
}
.no-cursor {
  cursor: not-allowed !important;
}
</style>