<template>
    <div class="businessInfo-wrap">
        <h2>Business Information</h2>
        <p>Enter and Update all your Business Information here.</p>
        <div class="businessInfo-form">
            <form action="">
                <div class="form-group row">
                    <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
                        <label for="">Business Name </label>
                        <input type="text" placeholder="Enter Business Name" name="business_name" class="accountInfo-field" v-model="businessInfo.business_name" @input="handleInputChange">
                        <div v-if="errors.business_name" class="error-message"><small>{{errors.business_name}}</small></div>
                    </div>
                    <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
                        <label for="">Business TAX ID </label>
                        <input type="text" placeholder="Enter Business TAX ID" name="business_tax_id" class="accountInfo-field" v-model="businessInfo.business_tax_id" @input="handleInputChange">
                        <div v-if="errors.business_tax_id" class="error-message"><small>{{errors.business_tax_id}}</small></div>
                    </div>
                </div>

                <div class="form-group row ai-image-wrapper">
                    <div class="col-md-6 col-sm-12 mb-3">
                        <input-image
                            @deleteImage="deleteImage('business')"
                            :rules="{ required: false }"
                            ref="image"
                            labelFor="image"
                            labelName="Upload Image"
                            :vmodel.sync="businessLogo"
                            formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4 image-row"
                            :imgSrc="businessInfo.logo"
                            imgClass="downline-upload-img"/>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-3">
                          <input-image
                           @deleteImage="deleteImage('business_cover')"
                            :rules="{ required: false }"
                            ref="image"
                            labelFor="image"
                            labelName="Cover Image"
                            :vmodel.sync="businessCoverImage"
                            formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4 image-row"
                            :imgSrc="businessInfo.cover_image"
                            imgClass="downline-upload-img"/>

                        
                    </div>
                </div>
                <div v-if="showAddressInfo" class="form-group row ai-location-wrapper">
                    <label for="">Address Information <i class="fas fa-plus-circle" v-b-modal.addAddress></i></label>
                    <div v-for="address in addressInfo" :key="address.id" class="col-md-6 col-sm-12 mb-3">
                        <div v-if="address.is_active" class="location-div primary-location">
                            <p>{{address.address1}}, {{address.address2}} <i :class="address.usps_verified ? 'fas fa-check-circle' : 'fas fa-exclamation-triangle'" v-b-tooltip.hover :title="address.usps_verified ? 'Validated with USPS' : 'Not validated with USPS'"></i> <br>{{address.city}}, {{address.state}}, {{address.zip}}</p>
                            <button class="primaryAdrs-btn">Primary</button>
                        </div>
                        <div v-else class="location-div set-as-primary">
                            <p>{{address.address1}}, {{address.address2}} <i :class="address.usps_verified ? 'fas fa-check-circle' : 'fas fa-exclamation-triangle'" v-b-tooltip.hover :title="address.usps_verified ? 'Validated with USPS' : 'Not validated with USPS'"></i> <br>{{address.city}}, {{address.state}}, {{address.zip}}</p>
                            <button type="button" class="primaryAdrs-btn" @click="updateAsPrimary(address.id)">Set as Primary</button>
                            <a class="ai-delete-a" type="button" v-b-tooltip.hover title="Delete" @click="deleteAddress(address.id)"><i class="fas fa-trash"></i></a>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                        <button v-if="processing" type="button" class="univ-btn no-cursor"> Processing... </button>
                        <button v-else type="button" class="univ-btn" v-on:click="updateBusinessInfo()"> Update </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import Api from '../../resource/Api.js';
import Helper from '../../resource/Helper.js';
import dummyImage from "@/assets/images/upload-dummy.png"
export default {
    name: 'BusinessInfo',
    props: {
        tabIndex:{
            required:true
        }
    },
    data() {
        return {
            type: 'business',
            businessInfo: {
                business_name: '',
                business_tax_id: '',
                logo: ''
            },
            agentImage: {
                image: '',
                cover_image: ''
            },
            addressInfo: [],
            imageUpdate: false,
            businessInfoUpdate: false,
            showAddressInfo: false,
            errors: [],
            show: false,
            version: process.env.VUE_APP_API_VERSION,
            processing: false,
            dummyImage,
            businessLogo: null,
            businessCoverImage: null,
        }
    },
    created() {
        //get business info
        this.getAgentBusinessInfo();
    },
    computed: {
        addressStatus() {
            return this.$store.getters.getAddressListStatus;
        } 
    },
    watch: {
        addressStatus: {
            handler: function (newValue) {
                if (newValue) {
                    this.getAddressList();
                }
            }
        }
    },
    methods: {
        // get informations
        getAgentBusinessInfo() {
            Api.get(`${this.version}/get-agent-info`)
            .then((response) => {
                if (response.data.status == 'success') {
                    this.businessInfo = {...response.data.data.business};
                    if (this.businessInfo.business_name || this.businessInfo.business_tax_id) {
                        this.getAddressList();
                    }
                } 
                else if (response.data.statusCode == 401 || response.data.status == 'error') {
                    console.log(response.data.message);
                }
            })
            .catch(e => {
                console.log(e.response.data.message);
                Api.notification('error', 'Failed to get agent business information'); 
            });
        },
        getAddressList() {
            //get list of address if there is business info
            Api.get(`${this.version}/get-address-list?type=business`)
            .then((response) => {
                if (response.data.status == "success") {
                    this.addressInfo = [...response.data.data];
                    //status false to end loop function call from watch
                    this.$store.commit('setAddressInfoStatus', false);
                }
                else {
                    console.log(response);
                }
            })
            .catch(e => {
                console.log(e.response.data.message);
                Api.notification('error', 'Failed to get address list'); 
            });
            this.showAddressInfo = true;
        },
        //validation
        validateBusinessInfo() {
            let result = true;
            let validationArr = [];

            if (!this.businessInfo.business_name) {
                // pushing into array for error messages
                validationArr['business_name'] = "Business Name cannot be empty";
                result = false;
            }

            if (!this.businessInfo.business_tax_id) {
                validationArr['business_tax_id'] = "Business TAX ID cannot be empty";
                result = false;
            }
            
            this.errors = validationArr;
            return result;
        },
        handleInputChange(event) {
            this.$set(this.errors, event.target.name, '');
            this.businessInfoUpdate= true;
        },
  
        updateBusinessInfo() {
            this.processing= true;
            let validation = this.validateBusinessInfo();

            if (validation) {
              let formData = new FormData();
              formData.append('business_name', this.businessInfo.business_name);
              formData.append('business_tax_id', this.businessInfo.business_tax_id);

              if (this.businessLogo) {
              formData.append('image', this.businessLogo);
              }
              if(this.businessCoverImage) {
                  formData.append('cover_image', this.businessCoverImage);
              }
             
              Api.post(`${this.version}/update-agent-business`, formData)
              .then((response) => {
                  if (response.data.status == 'success') {
                      if (!this.imageUpdate) {
                          this.getAgentBusinessInfo();    
                          this.processing = false;
                      }
                      this.businessInfoUpdate = false;
                      Api.notification(response.data.status, response.data.message); 
                  }
                  else if (response.data.statusCode == 401) {
                      this.processing = false;
                      Api.notification(response.data.status, response.data.message);     
                  }
              })
              .catch(e => {
                  this.processing = false;
                  console.log(e.response.data.message);
                  Api.notification(e.response.data.status, e.response.data.message); 
              });
            }
            else {
                this.processing = false;
            }
        },
        async updateAsPrimary(id) {
            const update = await Helper.confirmDialog('Confirm', 'Are you sure you want to update primary address?')
            if (update) {
                Api.post(`${this.version}/set-primary-address`,{
                    type: this.type,
                    address_id: id                
                })
                .then((response) => {
                    if (response.data.status == 'success') {
                        this.getAddressList();     
                        Api.notification(response.data.status, response.data.message);
                    }
                    else {
                        Api.notification(response.data.status, response.data.message);     
                    }
                })
                .catch (e => {
                    console.log(e.response.data.message);
                    Api.notification('error', 'Failed to update address as primary'); 
                });
            }
        },
        async deleteAddress(id) {
            const del = await Helper.confirmDialog('Confirm', 'Are you sure to delete this address?');

            if (del) {
                Api.delete(`${this.version}/delete-agent-address`, {
                    type: this.type,
                    address_id: id
                })
                .then((response) => {
                    if (response.data.status == 'success') {
                        this.getAddressList();     
                        Api.notification(response.data.status, response.data.message);
                    }
                    else {
                        Api.notification(response.data.status, response.data.message);     
                    }
                })
                .catch (e => {
                    console.log(e.response.data.message);   
                    Api.notification('error', 'Failed to delete address'); 
                });
            }
        },
        deleteImage(type) {
            this.$emit('deleteImage',type);
        }
        // setType() {
        //     this.$store.commit('setCurrentAccountInfoType', this.type);
        // }
    }
}
</script>

<style>
    .error-message {
        font-size: 13px;
        color: #dc3545;
        margin-top: 2px;
    }
    .uploaded {
    width: 102%;
}
</style>