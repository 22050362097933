<template>
  <div class="page-wrapper accInfo-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>Account Information</h1>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="accInfo-card">
            <b-card no-body>
              <b-tabs card v-model="tabIndex">
                <b-tab
                  title="Personal Information"
                  active
                  @click="tabIndex = 0;addressType='personal'"
                >
                  <b-card-text>
                    <personal-info ref="personal" @deleteImage='deleteImage' :tabIndex="tabIndex"/>
                  </b-card-text>
                </b-tab>
                <b-tab title="Business Information" @click="tabIndex = 1;addressType='business'">
                  <b-card-text>
                    <business-info ref="businessInfo" @deleteImage='deleteImage' :tabIndex="tabIndex" />
                  </b-card-text>
                </b-tab>
                <b-tab title="Payment Information" @click="tabIndex = 2;addressType='payment'">
                  <b-card-text>
                    <payment-info
                      :tabIndex="tabIndex"
                      :payment-method="paymentType"
                      @onPaymentChange="getAgentPersonalInfo"
                    />
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card>
            <new-address :type="addressType"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import PersonalInfo from "../components/AccountInfo/PersonalInfo.vue";
import BusinessInfo from "../components/AccountInfo/BusinessInfo.vue";
import PaymentInfo from "../components/AccountInfo/PaymentInfo.vue";
import NewAddress from "../components/AccountInfo/NewAddress.vue";
export default {
  name: "AccountInformation",
  components: {
    PersonalInfo,
    BusinessInfo,
    PaymentInfo,
    NewAddress,
  },
  data: () => ({
    paymentType: "",
    tabIndex: 0,
    addressType:"personal",
    breadcrumbItems: [{ title: "Account Information", active: true }],
  }),
  methods: {
    getAgentPersonalInfo() {
      Api.get(`v2/get-agent-info`)
        .then((response) => {
          if (response.data.status == "success") {
            this.paymentType = response.data.data.default_payment_type;
          } else {
            this.paymentType = "check";
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
   async deleteImage(type = '') {
       const del = await Helper.confirmDialog('Confirm', 'Are you sure to delete this image?');
            if (del) {
               return Api.delete(`v2/delete-agent-image`, {
                    type: type
                })
                .then((response) => {
                        this.$refs.personal.getAgentPersonalInfo()
                        this.$refs.businessInfo.getAgentBusinessInfo()
                        Api.notification(response.data.status, response.data.message);     
                })
                .catch (e => {
                  console.log(e);
                    Api.notification('error', 'Failed to delete image'); 
                });
            }
        },
  },
  created() {
    this.getAgentPersonalInfo();
  },
};
</script>
<style src="../assets/css/accountInfo.css"></style>