var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper accInfo-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "accInfo-card"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-tabs', {
    attrs: {
      "card": ""
    },
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Personal Information",
      "active": ""
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 0;
        _vm.addressType = 'personal';
      }
    }
  }, [_c('b-card-text', [_c('personal-info', {
    ref: "personal",
    attrs: {
      "tabIndex": _vm.tabIndex
    },
    on: {
      "deleteImage": _vm.deleteImage
    }
  })], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Business Information"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 1;
        _vm.addressType = 'business';
      }
    }
  }, [_c('b-card-text', [_c('business-info', {
    ref: "businessInfo",
    attrs: {
      "tabIndex": _vm.tabIndex
    },
    on: {
      "deleteImage": _vm.deleteImage
    }
  })], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Payment Information"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 2;
        _vm.addressType = 'payment';
      }
    }
  }, [_c('b-card-text', [_c('payment-info', {
    attrs: {
      "tabIndex": _vm.tabIndex,
      "payment-method": _vm.paymentType
    },
    on: {
      "onPaymentChange": _vm.getAgentPersonalInfo
    }
  })], 1)], 1)], 1)], 1), _c('new-address', {
    attrs: {
      "type": _vm.addressType
    }
  })], 1)])])], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Account Information")])]);
}]

export { render, staticRenderFns }