<template>
  <div class="paymentInfo-wrap">
    <h2>Payment Information</h2>
    <p>Select payment method or add new one.</p>
    <div class="paymentInfo-form" id="selector">
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input bank-details-switch"
          id="customSwitch1"
          v-model="isAch"
          @click="changePaymentMethod"
        />
        <label class="custom-switch-span" for="customSwitch1">
          Supply bank information at a later date
        </label>
        <label class="custom-control-label ach-label" for="customSwitch1">
          ACH
        </label>
      </div>
      <div v-if="paymentMethod === 'check'" class="check form-cheque">
        <h3>Supply bank information at a later date</h3>
        <div class="form-group row ai-location-wrapper">
          <label for="">
            Address Information
            <i
              class="fas fa-plus-circle"
              @click.prevent="$bvModal.show('addPaymentAddress')"
              style="cursor: pointer;"
            />
          </label>
          <div
            v-for="address in addressInfo"
            :key="address.id"
            class="col-md-6 col-sm-12 mb-3"
          >
            <div v-if="address.is_active" class="location-div primary-location">
              <p>
                {{ address.address1 }}, {{ address.address2 }}
                <i
                  :class="
                    address.usps_verified
                      ? 'fas fa-check-circle'
                      : 'fas fa-exclamation-triangle'
                  "
                  v-b-tooltip.hover
                  :title="
                    address.usps_verified
                      ? 'Validated with USPS'
                      : 'Not validated with USPS'
                  "
                ></i>
                <br />
                {{ address.city }}, {{ address.state }}, {{ address.zip }}
              </p>
              <button class="primaryAdrs-btn">Primary</button>
            </div>
            <div v-else class="location-div set-as-primary">
              <p>
                {{ address.address1 }}, {{ address.address2 }}
                <i
                  :class="
                    address.usps_verified
                      ? 'fas fa-check-circle'
                      : 'fas fa-exclamation-triangle'
                  "
                  v-b-tooltip.hover
                  :title="
                    address.usps_verified
                      ? 'Validated with USPS'
                      : 'Not validated with USPS'
                  "
                ></i>
                <br />
                {{ address.city }}, {{ address.state }}, {{ address.zip }}
              </p>
              <button
                type="button"
                class="primaryAdrs-btn"
                @click="updateAsPrimary(address.id)"
              >
                Set as Primary
              </button>
              <a
                class="ai-delete-a"
                type="button"
                v-b-tooltip.hover
                title="Delete"
                @click="deleteAddress(address.id)"
              >
                <i class="fas fa-trash"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="paymentMethod === 'ach'" class="ach form-ach">
        <h3>
          ACH
          <i class="fas fa-plus-circle" v-b-modal.addAch></i>
        </h3>
        <div class="fileListing-card">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Bank</th>
                  <th>Account Number</th>
                  <th>Routing</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ach in achList" :key="ach.id">
                  <td>
                    <span class="date">{{ ach.payee_name }}</span>
                  </td>
                  <td>
                    <span class="username">{{ ach.bank }}</span>
                  </td>
                  <td>
                    <span class="username">{{ ach.account_number }}</span>
                  </td>
                  <td>
                    <span class="username-code">
                      {{ ach.routing_number }}
                    </span>
                  </td>
                  <td>
                    <div class="action-icons">
                      <button v-if="ach.is_active" class="primaryAdrs-btn">
                        Primary
                      </button>
                      <button
                        v-else
                        class="primaryAdrs-btn set-as-primary"
                        v-b-tooltip.hover
                        title="Set as primary"
                        @click="setAsPrimary(ach.id)"
                      >
                        Set as Primary
                      </button>
                      <a
                        v-if="!ach.is_active"
                        type="button"
                        class="ai-delete-a"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="deleteAch(ach.id)"
                      >
                        <i class="fas fa-trash"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="addAch-popup">
        <b-modal id="addAch" centered size="lg">
          <template #modal-header>
            <div class="addAch-header">
              <h4>Add New ACH</h4>

              <b-button variant="close-btn" @click="closeAchModal">
                <i class="fas fa-times"></i>
              </b-button>
            </div>
          </template>
          <b-container fluid>
            <div class="addAch-form">
              <div class="form-group row" ref="achAddContainer">
                <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
                  <label for="">Payee Name</label>
                  <input
                    type="text"
                    placeholder="Enter Payee Name"
                    name="payee_name"
                    class="accountInfo-field"
                    v-model="paymentInfo.payee_name"
                    @input="handleInputChange"
                  />
                  <div v-if="errors.payee_name" class="error-message">
                    <small>{{ errors.payee_name }}</small>
                  </div>
                </div>
                <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
                  <label for="">Routing Number</label>
                  <input
                    type="text"
                    placeholder="Enter Routing Number"
                    name="routing_number"
                    class="accountInfo-field"
                    v-model="paymentInfo.routing_number"
                    @change="updateRoutingNumber"
                  />
                  <div v-if="errors.routing_number" class="error-message">
                    <small>{{ errors.routing_number }}</small>
                  </div>
                </div>

                <input-text
                  :rules="{ required: true /*regex:/^([a-zA-Z0-9 ])*$/ */ }"
                  labelFor="bank"
                  labelName="Bank Name"
                  inputName="bank_name"
                  inputClass="bank_name accountInfo-field"
                  :vmodel="paymentInfo.bank"
                  formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
                  :disabled="true"
                />
              </div>

              <div class="form-group row">
                <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
                  <label for="">Account Number</label>
                  <input
                    type="text"
                    placeholder="Enter Account Number"
                    name="account_number"
                    class="accountInfo-field"
                    v-model="paymentInfo.account_number"
                    @input="handleInputChange"
                  />
                  <div v-if="errors.account_number" class="error-message">
                    <small>{{ errors.account_number }}</small>
                  </div>
                </div>
                <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
                  <label for="">Account Type</label>
                  <select
                    class="accountInfo-field"
                    name="account_type"
                    v-model="paymentInfo.account_type"
                    @input="handleInputChange"
                  >
                    <option selected value="">Select Your Account Type</option>
                    <option value="Savings">Savings</option>
                    <option value="Checking">Checking</option>
                  </select>
                  <div v-if="errors.account_type" class="error-message">
                    <small>{{ errors.account_type }}</small>
                  </div>
                </div>
                <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
                  <label for="">Account Holder Type</label>
                  <select
                    class="accountInfo-field"
                    name="account_holder_type"
                    v-model="paymentInfo.account_holder_type"
                    @input="handleInputChange"
                  >
                    <option selected value="">
                      Select Account Holder Type
                    </option>
                    <option value="Individual">Individual</option>
                    <option value="Company">Company</option>
                  </select>
                  <div v-if="errors.account_holder_type" class="error-message">
                    <small>{{ errors.account_holder_type }}</small>
                  </div>
                </div>
                <!-- <div class="col-xxl-4 col-lg-4 col-md-6 mb-3">
                  <toggle-element
                    :vmodel.sync="paymentInfo.is_primary"
                    @onClicked="paymentInfo.is_primary = !paymentInfo.is_primary"
                    text="Set as Primary"
                  />
                </div> -->
              </div>

              <div class="form-group row">
                <div class="col-12">
                  <div class="signature">
                    <label>
                      Signature
                      <span style="color: red;">*</span>
                    </label>
                    <VueSignaturePad
                      width="100%"
                      height="200px"
                      style="border: 1px solid rgb(206, 212, 218);"
                      ref="signaturePad"
                      :options="{ onBegin, onEnd }"
                    />
                    <div class="text-right" style="text-align: right;">
                      <a
                        href="#"
                        @click="undo"
                        class="clearSignature"
                        style="
                          background: #0ab5e0;
                          padding: 5px 25px;
                          display: inline-block;
                          border-radius: 4px;
                          margin-top: 10px;
                          color: #fff;
                          text-decoration: none;
                        "
                      >
                        Undo
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-container>

          <template #modal-footer>
            <div class="w-100 text-center">
              <b-button class="univ-btn" @click="handleAchSubmit">
                Add ACH
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>
    </div>

    <new-payment-address
      @onSuccessPaymentAdd="updatePaymentMethodViaCheck"
      @onClosePaymentModal="getPaymentData"
    />
  </div>
</template>
<script>
import Api from '../../resource/Api.js'
import Helper from '../../resource/Helper.js'
import axios from 'axios'
import NewPaymentAddress from './NewPaymentAddress.vue'

const emptyPaymentInfo = {
  account_number: null,
  bank: '',
  payee_name: '',
  routing_number: null,
  account_type: '',
  account_holder_type: '',
  is_primary: false,
  bank_sign: null,
}

export default {
  name: 'PaymentInfo',
  props: {
    paymentMethod: {
      required: true,
    },
    tabIndex: {
      required: true,
    },
  },
  components: {
    NewPaymentAddress,
  },
  data() {
    return {
      type: 'payment',
      variants: ['close-btn'],
      paymentInfo: { ...emptyPaymentInfo },
      addressInfo: [],
      achList: [],
      errors: [],
      version: process.env.VUE_APP_API_VERSION,
      newPaymentMethod: '',
      isAch: false,
    }
  },
  computed: {
    addressStatus() {
      return this.$store.getters.getAddressListStatus
    },
  },
  watch: {
    tabIndex: function (val) {
      if (val == 2) {
        if (this.paymentMethod == 'ach') {
          this.getPaymentAchList()
          this.isAch = true
          this.newPaymentMethod = 'ach'
        } else if (this.paymentMethod == 'check') {
          this.getPaymentAddressList()
          this.isAch = false
          this.newPaymentMethod = 'check'
        }
      }
    },
    addressStatus: {
      handler: function (newValue) {
        if (newValue) {
          this.getPaymentAddressList()
        }
      },
    },
  },
  methods: {
    updatePaymentMethodViaCheck() {
      if (this.newPaymentMethod != 'check') {
        this.updatePaymentMethod('check')
      }
      this.$bvModal.hide('addPaymentAddress')
    },
    updatePaymentMethod(newPayment) {
      let loader = this.$loading.show()
      let url = `v1/update-agent-payment?default_payment_type=${newPayment}`
      Api.post(url)
        .then((res) => {
          let response = res.data.message
          this.newPaymentMethod = newPayment
          this.$notify(response)
          this.$emit('onPaymentChange')
        })
        .catch((err) => {
          this.$notify(err.response.data.message, 'error')
          this.newPaymentMethod = this.paymentMethod
        })
        .finally(() => {
          loader.hide()
          this.getPaymentData()
        })
    },
    getPaymentData() {
      if (this.newPaymentMethod == 'ach') {
        this.getPaymentAchList()
        this.isAch = true
      } else if (this.newPaymentMethod == 'check') {
        this.getPaymentAddressList()
        this.isAch = false
      }
    },
    changePaymentMethod() {
      this.$confirm({
        title: 'Change Payment',
        message: `Do you want to change payment method?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: (confirm) => {
          if (confirm) {
            let newPayment = ''
            if (this.newPaymentMethod == 'check') {
              newPayment = 'ach'
            } else {
              newPayment = 'check'
            }
            if (newPayment == 'ach') {
              Api.get(`${this.version}/get-ach-list`)
                .then((response) => {
                  this.achList = [...response.data.data]
                  if (this.achList.length < 1) {
                    this.$bvModal.show('addAch')
                  } else {
                    this.updatePaymentMethod('ach')
                  }
                })
                .catch((err) => {
                  if (err.response.data.message == 'No data found') {
                    this.$bvModal.show('addAch')
                  }
                })
            } else if (newPayment == 'check') {
              Api.get(`${this.version}/get-address-list?type=payment`)
                .then((response) => {
                  this.addressInfo = [...response.data.data]
                  if (this.addressInfo.length < 1) {
                    this.$bvModal.show('addPaymentAddress')
                  } else {
                    this.updatePaymentMethod('check')
                  }
                })
                .catch((err) => {
                  if (err.response.data.message == 'No data found') {
                    this.$bvModal.show('addPaymentAddress')
                  }
                })
            }
          } else {
            this.newPaymentMethod = this.paymentMethod
            if (this.newPaymentMethod == 'ach') {
              this.isAch = true
            } else {
              this.isAch = false
            }
          }
        },
      })
    },
    undo() {
      this.$refs.signaturePad.undoSignature()
    },
    onBegin() {
      this.$refs.signaturePad.resizeCanvas()
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if (isEmpty == false) {
        this.paymentInfo.bank_sign = data
        this.signErrorText = ''
      }
    },
    getPaymentAddressList() {
      Api.get(`${this.version}/get-address-list?type=payment`)
        .then((response) => {
          if (response.data.status == 'success') {
            this.addressInfo = [...response.data.data]
            if (this.addressInfo.length < 1) {
              this.$bvModal.show('addPaymentAddress')
            }
            //status false to end loop function call from watch
            this.$store.commit('setAddressInfoStatus', false)
          } else {
            console.log(response)
          }
        })
        .catch((e) => {
          console.log(e.response.data.message)
          Api.notification('error', 'Failed to get payment address')
          if (e.response.data.message == 'No data found') {
            this.$bvModal.show('addPaymentAddress')
          }
        })
    },
    getPaymentAchList() {
      Api.get(`${this.version}/get-ach-list`)
        .then((response) => {
          if (response.data.status == 'success') {
            this.achList = [...response.data.data]
            if (this.achList.length < 1) {
              this.$bvModal.show('addAch')
            }
          } else {
            console.log(response)
          }
        })
        .catch((e) => {
          console.log(e.response.data.message)
          Api.notification('error', 'Failed to get payment ACH list')
          if (e.response.data.message == 'Agent ACH data not found.') {
            this.$bvModal.show('addAch')
          }
        })
    },
    //for address info
    async updateAsPrimary(id) {
      const update = await Helper.confirmDialog(
        'Confirm',
        'Are you sure you want to update primary address?',
      )
      if (update) {
        Api.post(`${this.version}/set-primary-address`, {
          type: this.type,
          address_id: id,
        })
          .then((response) => {
            if (response.data.status == 'success') {
              this.getPaymentAddressList()
              Api.notification(response.data.status, response.data.message)
            } else {
              Api.notification(response.data.status, response.data.message)
            }
          })
          .catch((e) => {
            console.log(e.response)
            Api.notification('error', 'Failed to update address as primary')
          })
      }
    },
    //for Ach payment
    async setAsPrimary(id) {
      const update = await Helper.confirmDialog(
        'Confirm',
        'Are you sure you want to update to primary ACH?',
      )
      if (update) {
        Api.post(`${this.version}/set-primary-ach`, {
          payment_id: id,
        })
          .then((response) => {
            if (response.data.status == 'success') {
              this.getPaymentAchList()
              Api.notification(response.data.status, response.data.message)
            } else {
              Api.notification(response.data.status, response.data.message)
            }
          })
          .catch((e) => {
            console.log(e.response)
            Api.notification('error', 'Failed to set ACH as primary')
          })
      }
    },
    routingNumberHandle(event) {
      this.$set(this.errors, event.target.name, '')
      if (this.paymentInfo.routing_number.length >= 9) {
        // created new axios instance (constant) to prevent global axios interceptors
        const uninterceptedAxiosInstance = axios.create()
        uninterceptedAxiosInstance
          .get(
            'https://www.routingnumbers.info/api/name.json?rn=' +
              this.paymentInfo.routing_number,
          )
          .then((response) => {
            if (response.data.code == 200) {
              let bank = response.data.name
              this.paymentInfo = { ...this.paymentInfo, bank }
              this.$set(this.errors, 'bank', '')
            } else if (response.data.code == 404) {
              let bank = ''
              this.paymentInfo = { ...this.paymentInfo, bank }
              Api.notification('error', 'Routing number not found')
              this.$set(
                this.errors,
                event.target.name,
                'Routing number not found',
              )
            }
          })
          .catch((e) => {
            let bank = ''
            this.paymentInfo = { ...this.paymentInfo, bank }
            console.log(e)
            Api.notification('error', 'Failed to get bank')
          })
      }
    },
    validateAch() {
      let result = true
      let errorArr = []

      let re = /^[0-9]*$/

      if (!this.paymentInfo.payee_name) {
        errorArr['payee_name'] = 'Payee Name cannot be empty'
        result = false
      }

      if (!this.paymentInfo.bank) {
        errorArr['bank'] = 'Bank not found (Check your routing number)'
        result = false
      }

      if (!this.paymentInfo.account_number) {
        errorArr['account_number'] = 'Account Number cannot be empty'
        result = false
      } else if (!re.test(this.paymentInfo.account_number)) {
        errorArr['account_number'] = 'Account Number should be in number'
        result = false
      } else if (
        this.paymentInfo.account_number.length < 4 ||
        this.paymentInfo.account_number.length > 17
      ) {
        errorArr['account_number'] =
          'Account Number should be between 4 to 17 digits.'
        result = false
      }

      if (!this.paymentInfo.routing_number) {
        errorArr['routing_number'] = 'Routing Number cannot be empty'
        result = false
      } else if (this.paymentInfo.routing_number.length != 9) {
        errorArr['routing_number'] = 'Routing Number should be of 9 digits'
        result = false
      } else if (!re.test(this.paymentInfo.routing_number)) {
        errorArr['routing_number'] = 'Routing Number should be in number'
        result = false
      }

      if (!this.paymentInfo.account_type) {
        errorArr['account_type'] = 'Please select an account type.'
        result = false
      }

      if (!this.paymentInfo.account_holder_type) {
        errorArr['account_holder_type'] =
          'Please select an account holder type.'
        result = false
      }

      this.errors = errorArr
      return result
    },
    handleInputChange(event) {
      this.$set(this.errors, event.target.name, '')
    },
    handleAchSubmit() {
      const validation = this.validateAch()
      if (validation) {
        let loader = this.$loading.show({
          container: this.$refs.achAddContainer,
        })
        Api.post(`${this.version}/add-payment-ach`, this.paymentInfo)
          .then((response) => {
            if (response.data.status == 'success') {
              this.getPaymentAchList()
              Api.notification(response.data.status, response.data.message)
              if (this.newPaymentMethod != 'ach') {
                this.updatePaymentMethod('ach')
              }
              this.paymentInfo = { ...emptyPaymentInfo }
              this.$bvModal.hide('addAch')
            } else {
              Api.notification(response.data.status, response.data.message)
            }
          })
          .catch((e) => {
            console.log(e.response)
            Api.notification(e.response.data.status, e.response.data.message)
          })
          .finally(() => {
            loader.hide()
          })
      }
    },
    async deleteAddress(id) {
      const del = await Helper.confirmDialog(
        'Confirm',
        'Are you sure to delete this address?',
      )

      if (del) {
        Api.delete(`${this.version}/delete-agent-address`, {
          type: this.type,
          address_id: id,
        })
          .then((response) => {
            if (response.data.status == 'success') {
              this.getPaymentAddressList()
              Api.notification(response.data.status, response.data.message)
            } else {
              Api.notification(response.data.status, response.data.message)
            }
          })
          .catch((e) => {
            console.log(e.response.data.message)
            Api.notification('error', 'Failed to delete address')
          })
      }
    },
    async deleteAch(id) {
      const proceed = await Helper.confirmDialog(
        'Confirm',
        'Are you sure to delete this ACH?',
      )
      if (proceed) {
        Api.delete(`${this.version}/remove-agent-ach`, {
          payment_id: id,
        })
          .then((response) => {
            if (response.data.status == 'success') {
              this.getPaymentAchList()
              Api.notification(response.data.status, response.data.message)
            } else {
              Api.notification(response.data.status, response.data.message)
            }
          })
          .catch((e) => {
            console.log(e.response.data.message)
            Api.notification('error', 'Failed to delete ACH')
          })
      }
    },
    // togglePaymentMethod() {
    //   if (this.paymentMethod == "check") {
    //     this.paymentMethod = "ach";
    //     if (this.achList.length < 1) {
    //       this.$bvModal.show("addAch");
    //     }
    //   } else {
    //     this.paymentMethod = "check";
    //   }
    // },
    // setType() {
    //   this.$store.commit("setCurrentAccountInfoType", this.type);
    // },
    closeAchModal() {
      this.errors = []
      this.$bvModal.hide('addAch')
      this.getPaymentData()
    },
    updateRoutingNumber() {
      /*eslint-disable*/
      console.log('test')
      let url, pattern, routingNumber
      routingNumber = this.paymentInfo.routing_number
      console.log('routing', routingNumber)
      pattern = new RegExp('^\\d{9}$')
      this.paymentInfo.bank = ''

      if (routingNumber.match(pattern)) {
        url = `${this.$endpoint.VALIDATE_ROUTING_NUMBER_NEW}${routingNumber}`
        console.log(url)
        console.log('mathed', url)
        const otherUrl = axios.create({})
        otherUrl
          .get(url)
          .then((res) => {
            debugger
            this.paymentInfo.bank = ''
            if (res.data.statusCode == 200) {
              this.paymentInfo.bank = res.data.data.result.bank_name
              // document
              //.querySelector("#routing-number")
              //.classList.remove("is-invalid");
            } else if (res.data.statusCode == 404) {
              this.paymentInfo.bank = ''
              //document
              //.querySelector("#routing-number")
              //.classList.add("is-invalid");
              // document.querySelector(".routing-number-feedback").innerHTML =
              // "Invalid Routing Number";
            }
          })
          .catch(() => {
            this.paymentInfo.bank = ''
            this.$notify('Invalid Routing Number', 'Error', 'danger')
            //document
            // .querySelector("#routing-number")
            // .classList.add("is-invalid");
            // document.querySelector(".routing-number-feedback").innerHTML =
            // "Invalid Routing Number";
          })
      }
    },
  },
}
</script>
<style src="../../assets/css/myfiles.css"></style>
<style>
#addAch .modal-content {
  border: 0px;
  border-radius: 10px;
}
#addAch .modal-header {
  justify-content: center;
  border: 0;
  padding: 25px 0;
  position: relative;
  border-bottom: 0;
}
#addAch .modal-footer {
  border-top: 0;
  padding: 0 15px 25px 15px;
}
.addAch-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
  margin: 0;
}
.addAch-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.addAch-form label {
  color: #5e5e5e;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}
#addAch .modal-footer .univ-btn {
  max-width: 300px;
}
.bank-details-switch {
  margin-left: 28%;
}
@media (max-width: 640px) {
  .ach-label {
    margin-left: 40px !important;
    margin-bottom: 1rem !important;
  }
}
</style>
