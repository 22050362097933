var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "addAddress-popup"
  }, [_c('b-modal', {
    attrs: {
      "id": "addPaymentAddress",
      "centered": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "addAddress-header"
        }, [_c('h4', [_vm._v("Add CHCK Address")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 text-center"
        }, [_vm.processing ? _c('b-button', {
          staticClass: "univ-btn no-cursor",
          attrs: {
            "disabled": ""
          }
        }, [_vm._v("Processing...")]) : _c('b-button', {
          staticClass: "univ-btn",
          on: {
            "click": _vm.handleAddressSubmit
          }
        }, [_vm._v("Submit")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "addAddress-form"
  }, [_c('div', {
    ref: "newPaymentAddressContainer",
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Address 1 "), _c('span', {
    staticClass: "required-indc"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.address1,
      expression: "formData.address1"
    }],
    staticClass: "accountInfo-field",
    attrs: {
      "type": "text",
      "placeholder": "Enter Address 1",
      "name": "address1"
    },
    domProps: {
      "value": _vm.formData.address1
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.formData, "address1", $event.target.value);
      }, _vm.handleInputChange]
    }
  }), _vm.errors.address1 ? _c('div', {
    staticClass: "error-message"
  }, [_c('small', [_vm._v(_vm._s(_vm.errors.address1))])]) : _vm._e()]), _c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Address 2")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.address2,
      expression: "formData.address2"
    }],
    staticClass: "accountInfo-field",
    attrs: {
      "type": "text",
      "placeholder": "Enter Address 2",
      "name": "address2"
    },
    domProps: {
      "value": _vm.formData.address2
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.formData, "address2", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("City "), _c('span', {
    staticClass: "required-indc"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.city,
      expression: "formData.city"
    }],
    staticClass: "accountInfo-field",
    attrs: {
      "type": "text",
      "placeholder": "Enter City",
      "name": "city"
    },
    domProps: {
      "value": _vm.formData.city
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.formData, "city", $event.target.value);
      }, _vm.handleInputChange]
    }
  }), _vm.errors.city ? _c('div', {
    staticClass: "error-message"
  }, [_c('small', [_vm._v(_vm._s(_vm.errors.city))])]) : _vm._e()]), _c('div', {
    staticClass: "col-xlx-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("State "), _c('span', {
    staticClass: "required-indc"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.state,
      expression: "formData.state"
    }],
    staticClass: "accountInfo-field",
    attrs: {
      "name": "state"
    },
    on: {
      "input": _vm.handleInputChange,
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.formData, "state", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "selected": ""
    }
  }, [_vm._v("Select Your State")]), _vm._l(_vm.states, function (state) {
    return _c('option', {
      key: state.value,
      domProps: {
        "value": state.value
      }
    }, [_vm._v(" " + _vm._s(state.text) + " ")]);
  })], 2), _vm.errors.state ? _c('div', {
    staticClass: "error-message"
  }, [_c('small', [_vm._v(_vm._s(_vm.errors.state))])]) : _vm._e()])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Zip "), _c('span', {
    staticClass: "required-indc"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.zip,
      expression: "formData.zip"
    }],
    staticClass: "accountInfo-field",
    attrs: {
      "type": "text",
      "placeholder": "Enter Zip Code",
      "name": "zip"
    },
    domProps: {
      "value": _vm.formData.zip
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.formData, "zip", $event.target.value);
      }, _vm.handleInputChange]
    }
  }), _vm.errors.zip ? _c('div', {
    staticClass: "error-message"
  }, [_c('small', [_vm._v(_vm._s(_vm.errors.zip))])]) : _vm._e()])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "form-check col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('toggle-element', {
    attrs: {
      "vmodel": _vm.formData.is_primary,
      "text": "Set as Primary"
    },
    on: {
      "update:vmodel": function updateVmodel($event) {
        return _vm.$set(_vm.formData, "is_primary", $event);
      },
      "onClicked": function onClicked($event) {
        _vm.formData.is_primary = !_vm.formData.is_primary;
      }
    }
  })], 1), _c('div', {
    staticClass: "form-check col-xxl-6 col-lg-6 col-md-6 mb-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.usps_verified,
      expression: "formData.usps_verified"
    }],
    staticClass: "form-check-input add-new-address-checkbox",
    attrs: {
      "type": "checkbox",
      "id": "flexCheckDefault"
    },
    domProps: {
      "checked": Array.isArray(_vm.formData.usps_verified) ? _vm._i(_vm.formData.usps_verified, null) > -1 : _vm.formData.usps_verified
    },
    on: {
      "click": _vm.uspsValidation,
      "change": function change($event) {
        var $$a = _vm.formData.usps_verified,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.formData, "usps_verified", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.formData, "usps_verified", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.formData, "usps_verified", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "form-check-label",
    attrs: {
      "for": "flexCheckDefault"
    }
  }, [_vm._v(" Validate with USPS ")])])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }